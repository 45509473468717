import { useMutation } from "@apollo/client"
import { useCallback } from "react"

import { useNotify } from "../alert/hooks"
import {
  ACTIVATE_ASSOCIATE,
  DEACTIVATE_ASSOCIATE,
  DELETE_ASSOCIATE_INVITE,
  RESEND_ASSOCIATE_INVITE,
} from "./mutations"

export const useDeactivateAssociate = (): ((
  userId: string
) => Promise<void>) => {
  const notify = useNotify()
  const [deactivateAssociateMutation] = useMutation(DEACTIVATE_ASSOCIATE, {
    context: { clientName: "private-api" },
  })

  const deactivateAssociate = useCallback(
    async (payload: string) => {
      try {
        const { data } = await deactivateAssociateMutation({
          variables: { input: { userId: payload } },
        })
        if (
          data.deactivateAssociate.__typename !== "DeactivateAssociateSuccess"
        ) {
          throw new Error("Error deactivating associate")
        }
      } catch (err) {
        if (err instanceof Error) {
          if (err.message === "401") {
            notify(
              "danger",
              "You are not authorized to deactivate this associate",
              "please try again later"
            )
          } else {
            notify(
              "danger",
              "Associate could not be deactivated",
              "please try again later"
            )
          }
        }
      }
    },
    [deactivateAssociateMutation, notify]
  )

  return deactivateAssociate
}

export const useActivateAssociate = (): ((userId: string) => Promise<void>) => {
  const notify = useNotify()
  const [activateAssociateMutation] = useMutation(ACTIVATE_ASSOCIATE, {
    context: { clientName: "private-api" },
  })

  const activateAssociate = useCallback(
    async (payload: string) => {
      try {
        const { data } = await activateAssociateMutation({
          variables: { input: { userId: payload } },
        })
        if (data.activateAssociate.__typename !== "ActivateAssociateSuccess") {
          throw new Error("Error activating associate")
        }
      } catch (err) {
        if (err instanceof Error) {
          if (err.message === "401") {
            notify(
              "danger",
              "You are not authorized to activate this associate",
              "please try again later"
            )
          } else {
            notify(
              "danger",
              "Associate could not be reactivated",
              "please try again later"
            )
          }
        }
      }
    },
    [activateAssociateMutation, notify]
  )

  return activateAssociate
}

export const useDeleteAssociateInvite = (): ((
  email: string
) => Promise<void>) => {
  const notify = useNotify()
  const [deleteInviteMutation] = useMutation(DELETE_ASSOCIATE_INVITE, {
    context: { clientName: "private-api" },
  })

  const deleteInvite = useCallback(
    async (payload: string) => {
      try {
        const { data } = await deleteInviteMutation({
          variables: { input: { email: payload } },
        })
        if (data.deleteInvite.__typename !== "DeleteInviteSuccess") {
          throw new Error("Error deleting invite")
        }
      } catch (err) {
        if (err instanceof Error) {
          if (err.message === "401") {
            notify(
              "danger",
              "You are not authorized to delete this invite",
              "please try again later"
            )
          } else {
            notify(
              "danger",
              "This invite could not be deleted",
              "please try again later"
            )
          }
        }
      }
    },
    [deleteInviteMutation, notify]
  )

  return deleteInvite
}

export const useResendAssociateInvite = (): ((
  email: string
) => Promise<void>) => {
  const notify = useNotify()

  const [resendInviteMutation] = useMutation(RESEND_ASSOCIATE_INVITE, {
    context: { clientName: "private-api" },
  })

  const resendInvite = useCallback(
    async (payload: string) => {
      try {
        const { data } = await resendInviteMutation({
          variables: { input: { email: payload } },
        })
        if (data.resendInvite.__typename === "InviteAssociateSuccess") {
          notify(
            "success",
            "Invite sent",
            "your associate should receive it shortly"
          )
        } else {
          throw new Error("Error resending invite")
        }
      } catch (err) {
        if (err instanceof Error) {
          if (err.message === "401") {
            notify(
              "danger",
              "You are not authorized to resend this invite",
              "please try again later"
            )
          } else {
            notify("danger", "Unable to send invite", "please try again later")
          }
        }
      }
    },
    [notify, resendInviteMutation]
  )

  return resendInvite
}
