import { gql } from "@apollo/client"

export const DEACTIVATE_ASSOCIATE = gql`
  mutation deactivateAssociate($input: DeactivateAssociateMutationInput!) {
    deactivateAssociate(input: $input) {
      ... on DeactivateAssociateSuccess {
        success
      }
      ... on NotFoundError {
        message
      }
      ... on InternalServerError {
        message
      }
    }
  }
`

export const ACTIVATE_ASSOCIATE = gql`
  mutation activateAssociate($input: ActivateAssociateMutationInput!) {
    activateAssociate(input: $input) {
      ... on ActivateAssociateSuccess {
        success
      }
      ... on NotFoundError {
        message
      }
      ... on InternalServerError {
        message
      }
    }
  }
`

export const DELETE_ASSOCIATE_INVITE = gql`
  mutation deleteInvite($input: DeleteInviteMutationInput!) {
    deleteInvite(input: $input) {
      ... on DeleteInviteSuccess {
        success
      }
      ... on NotFoundError {
        message
      }
      ... on ForbiddenError {
        message
      }
    }
  }
`

export const RESEND_ASSOCIATE_INVITE = gql`
  mutation resendInvite($input: ResendInviteMutationInput!) {
    resendInvite(input: $input) {
      ... on InviteAssociateSuccess {
        success
      }
      ... on NotFoundError {
        message
      }
      ... on ForbiddenError {
        message
      }
      ... on InternalServerError {
        message
      }
    }
  }
`
