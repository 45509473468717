import { Link } from "gatsby"
import React, { useState, useCallback } from "react"
import styled from "styled-components"

import { useGetAssociates, useUser } from "../../auth/hooks"
import {
  ActionDropdown,
  Chip,
  ContactIcons,
  Filter,
  InviteAssociateCard,
  PageHeader,
  PageGuard,
  PageNotificationBanner,
  Table as TableBase,
} from "../../components"
import Seo from "../../components/seo"
import { TableHeading } from "../../components/Table"
import {
  useActivateAssociate,
  useDeactivateAssociate,
  useDeleteAssociateInvite,
  useResendAssociateInvite,
} from "../../manage-associates/hooks"

const InviteCardContainer = styled.div`
  margin-top: 20px;
`

const Table = styled(TableBase)`
  margin: 20px 0;

  .table-row {
    grid-template-areas:
      "associate status"
      "training status"
      "contact action";

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
      grid-template-areas: "associate status training action contact";
    }
  }
`

const AssociateContainer = styled.div`
  grid-area: associate;

  .associate-email {
    font-weight: 500;
    line-height: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .associate-name {
    font-size: 12px;
    line-height: 22px;
  }
`

const ChipContainer = styled.div`
  display: flex;
  grid-area: status;
  justify-self: end;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    justify-self: start;
  }
`

const AssociateStatus = {
  PENDING: "PENDING",
  ACTIVATED: "ACTIVATED",
  DEACTIVATED: "DEACTIVATED",
}

interface FilterOption {
  title: string
  filterRef?: Reseller.AssociateStatusType
  quantity?: number
}

const renderChip = (status: string, index: number) => {
  const borderColor =
    status === AssociateStatus.ACTIVATED
      ? "rgba(0,169,224,0.3)"
      : status === AssociateStatus.PENDING
      ? "#e1eae8"
      : status === AssociateStatus.DEACTIVATED
      ? "#ffd4c6"
      : ""

  const textColor =
    status === AssociateStatus.ACTIVATED
      ? "#00a9e0"
      : status === AssociateStatus.PENDING
      ? "#014737"
      : status === AssociateStatus.DEACTIVATED
      ? "#fa5d37"
      : ""

  const text =
    status === AssociateStatus.ACTIVATED
      ? "Profile Active"
      : status === AssociateStatus.PENDING
      ? "Pending"
      : status === AssociateStatus.DEACTIVATED
      ? "Deactivated"
      : ""

  return (
    <ChipContainer key={`chip-container-${index}`}>
      <Chip
        text={text}
        backgroundColor="inherit"
        borderColor={borderColor}
        borderWidth="2px"
        textColor={textColor}
        size="medium"
        fontWeight="500"
      />
    </ChipContainer>
  )
}

const ManageAssociates = (): React.ReactElement => {
  const [selectedFilterIndex, setSelectedFilterIndex] = useState(0)
  const { user } = useUser()
  const { data, loading, refetch, fetchMore } = useGetAssociates()
  const deactivateAssociate = useDeactivateAssociate()
  const activateAssociate = useActivateAssociate()
  const resendAssociateInvite = useResendAssociateInvite()
  const deleteAssociateInvite = useDeleteAssociateInvite()

  const associates = data?.edges?.map((associate) => {
    return {
      id: associate.node.id,
      associateDetails: {
        name: associate.node.name,
        email: associate.node.email,
      },
      status: associate.node.status,
      trainingProgress: associate.node.trainingProgress,
      type: associate.node.type,
    }
  })

  const getActionsList = useCallback(
    (status: Reseller.AssociateStatusType, index: number) => {
      if (status === AssociateStatus.ACTIVATED) {
        return [
          {
            label: "Deactivate",
            onClick: () => {
              deactivateAssociate(data?.edges[index]?.node.userId)
              refetch({})
            },
          },
        ]
      } else if (status === AssociateStatus.DEACTIVATED) {
        return [
          {
            label: "Reactivate",
            onClick: () => {
              activateAssociate(data?.edges[index]?.node.userId)
              refetch({})
            },
          },
        ]
      } else {
        return [
          {
            label: "Resend invite",
            onClick: () => {
              resendAssociateInvite(data?.edges[index]?.node.email)
            },
          },
          {
            label: "Delete invite",
            onClick: () => {
              deleteAssociateInvite(data?.edges[index]?.node.email)
              refetch({})
            },
          },
        ]
      }
    },
    [
      deactivateAssociate,
      data?.edges,
      activateAssociate,
      resendAssociateInvite,
      deleteAssociateInvite,
      refetch,
    ]
  )

  const associateFilters: FilterOption[] = [
    {
      title: "All Associates",
      filterRef: undefined,
      quantity: data?.count?.all,
    },
    { title: "Pending", filterRef: "PENDING", quantity: data?.count?.pending },
    {
      title: "Profile Active",
      filterRef: "ACTIVATED",
      quantity: data?.count?.activated,
    },
    {
      title: "Deactivated",
      filterRef: "DEACTIVATED",
      quantity: data?.count?.deactivated,
    },
  ]

  const handleFilterChange = (index: number) => {
    refetch({
      status: associateFilters[index].filterRef,
    })
    setSelectedFilterIndex(index)
  }

  const handleFetchMoreAssociates = useCallback(() => {
    fetchMore({
      variables: {
        after: data?.pageInfo.endCursor,
      },
    })
  }, [fetchMore, data?.pageInfo.endCursor])

  const tableHeadings: TableHeading[] = [
    {
      name: "Reseller",
      accessor: "associateDetails",
      columnAllowance: "2fr",
      renderData: (
        index: number,
        details: {
          name: string
          email: string
        }
      ) => {
        return (
          <AssociateContainer key={`associate-${details.name}`}>
            <p className="associate-email">{details.email}</p>
            <p className="associate-name">{details.name}</p>
          </AssociateContainer>
        )
      },
    },
    {
      name: "Status",
      accessor: "status",
      columnAllowance: "1fr",
      renderData: (index: number, status: string) => renderChip(status, index),
    },
    {
      name: "Training",
      accessor: "trainingProgress",
      columnAllowance: "1fr",
      renderData: (index: number, progress: number) => {
        const trainingProgress =
          progress !== null ? Math.floor(progress) + "%" : "-"
        return (
          <p
            key={`training-progress-${index}`}
            style={{ gridArea: "training" }}
          >
            {trainingProgress}
          </p>
        )
      },
    },
    {
      name: "Actions",
      accessor: "status",
      columnAllowance: "1fr",
      renderData: (index: number, status: string) => {
        return (
          <div key={`actions-${index}`} style={{ gridArea: "action" }}>
            <ActionDropdown
              actions={getActionsList(
                status as Reseller.AssociateStatusType,
                index
              )}
            />
          </div>
        )
      },
    },
    {
      name: "Make contact",
      accessor: "contact",
      columnAllowance: "minmax(30px, max-content)",
      renderData: (index: number, data: any) => (
        <ContactIcons key={`contacts-${index}`} data={data} index={index} />
      ),
    },
  ]

  return (
    <PageGuard verifyUserProfile allowedProfileTypes={["PRINCIPAL"]}>
      <Seo title="Manage resellers" />
      <PageHeader
        cypressTestId="manage-resellers-page-header"
        title="Manage resellers"
      />
      <PageNotificationBanner>
        {user?.profile?.verificationStatus !== "SUCCESSFUL" ? (
          <p>
            Your identity has not been verified yet. Please{" "}
            <Link className="highlighted" to="/onfido/">
              verify your identity
            </Link>{" "}
            to list your associate Resellers.
          </p>
        ) : (
          <p>View and manage your associate Resellers</p>
        )}
      </PageNotificationBanner>
      {user?.profile?.verificationStatus === "SUCCESSFUL" ? (
        <>
          {" "}
          <Filter
            isPageFilter
            filterOptions={associateFilters}
            onClick={handleFilterChange}
            activeIndex={selectedFilterIndex}
          />
          <InviteCardContainer>
            <InviteAssociateCard
              externalHeading={false}
              onInviteReseller={() => setSelectedFilterIndex(0)}
            />
          </InviteCardContainer>
          <Table
            headings={tableHeadings}
            rowData={associates}
            loading={loading}
            emptyTableText="You have no associated Resellers to view"
            allRowsLoaded={!data?.pageInfo.hasNextPage}
            handleLoadMoreRows={handleFetchMoreAssociates}
          />
        </>
      ) : null}
    </PageGuard>
  )
}

export default ManageAssociates
